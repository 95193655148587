// export let serviceUrl = "https://dudhsagar.herdman.in";
// export let serviceUrl = "https://hais.hap.in";
// export let serviceUrl = "http://localhost:2000";
// let serviceUrl1 = "https://dudhsagar.herdman.in";
// let serviceUrl1 = "https://hais.hap.in";
// let testingUrl = "https://testing.herdman.in";

// Dudshagar
// export let serviceUrl = 'https://dudhsagar.herdman.in'
// export let serviceUrl1 = 'https://dudhsagar.herdman.in'

//Has
export let serviceUrl = "https://hais.hap.in";
export let serviceUrl1 = "https://hais.hap.in";

// // //Local Host
// export let serviceUrl = 'http://localhost:2000'
// export let serviceUrl1 = 'http://localhost:2000'

const path = localStorage.getItem('path')
const urlChange = path === 'demo.herdman.in' || path === '/'

export const API_URLS = {
  LoginAPI: {
    login: `${urlChange ? serviceUrl : serviceUrl1}/api/auth/login`,
    superAdmin: `${urlChange ? serviceUrl : serviceUrl1
      }/company/generate-token-company?name=Dudhsagar`,
    changePassword: `${urlChange ? serviceUrl : serviceUrl1
      }/auth/ChangePassword`,
    GetInfo: `${urlChange ? serviceUrl : serviceUrl1}/api/sp/Master/UserGet`
  },
  LoginAPIHap: {
    login: `${serviceUrl1}/auth/login`,
    superAdmin: `${serviceUrl1}/company/generate-token-company?name=Dudhsagar`
  },
  OverViewApi: {
    DeshboardGraphType: `${urlChange ? serviceUrl : serviceUrl1
      }/dashboard/get-graphtype`,
    UserActivity: `${urlChange ? serviceUrl : serviceUrl1
      }/dashboard/get-user-activity`,
    GetAnimalActivity: `${urlChange ? serviceUrl : serviceUrl1
      }/dashboard/get-animal-activity-by-user`,
    GetGraphByDate: `${urlChange ? serviceUrl : serviceUrl1
      }/dashboard/get-graph`,
    GetAlertData: `${urlChange ? serviceUrl : serviceUrl1}/api/sp/Alertupdate`
  },
  CattleReport: {
    GetAnimalTree: `${urlChange ? serviceUrl : serviceUrl1
      }/cattle-report/tree-get-all-herds`,
    GetTreebyId: `${urlChange ? serviceUrl : serviceUrl1
      }/cattle-report/tree-get-lot-by-herdId`
    // GetLifeTimeApi: `${urlChange ? serviceUrl : serviceUrl1}/cattle-report/tree-get-lot-by-herdId`,
  },
  HerdWiseAPI: {
    EditDetails: `${urlChange ? serviceUrl : serviceUrl1
      }/reportUnion/MilkUnionReportDetails`
  },
  CC: {
    GetDetails: `${urlChange ? serviceUrl : serviceUrl1}/cc/get_table_data`,
    AddDetails: `${urlChange ? serviceUrl : serviceUrl1}/cc/add`,
    UpdateDetails: `${urlChange ? serviceUrl : serviceUrl1}/cc/edit`,
    DelateDetails: `${urlChange ? serviceUrl : serviceUrl1}/cc/delete`,
    GetCcByName: `${urlChange ? serviceUrl : serviceUrl1}/cc/get_cc_by_name`,
    SuspendApi: `${urlChange ? serviceUrl : serviceUrl1}/cc/is_suspended`,
    AllCc: `${urlChange ? serviceUrl : serviceUrl1}/cc/getall`
  },
  DIAGNOSIS: {
    Update: `${urlChange ? serviceUrl : serviceUrl1}/diagnosis/edit`
  },
  SYSTEMAFFECTED: {
    Insert: `${urlChange ? serviceUrl : serviceUrl1}/system-affected/add`,
    Update: `${urlChange ? serviceUrl : serviceUrl1}/system-affected/edit`,
    Delete: `${urlChange ? serviceUrl : serviceUrl1}/system-affected/delete`
  },
  REPORT_UNION: {
    Select_Tab: `${urlChange ? serviceUrl : serviceUrl1
      }/reportUnion/getFillTab`,
    Select_Report: `${urlChange ? serviceUrl : serviceUrl1}/reportUnion/getall`,
    MilkCustomReport: `${urlChange ? serviceUrl : serviceUrl1
      }/reportUnion/MilkunionCustomReport`
  },
  DATEWISE: {
    Select_Report: `${urlChange ? serviceUrl : serviceUrl1
      }/reportUnion/getMilkUnionReportDatewise`,
    Select_Tab: `${urlChange ? serviceUrl : serviceUrl1
      }/reportUnion/getFillTab`,
    ReportDatewise: `${urlChange ? serviceUrl : serviceUrl1
      }/reportUnion/MilkUnionReportDatewise`
  },

  //medicine managment:
  OpeningBalanceApi: {
    CenterDetails: `${urlChange ? serviceUrl : serviceUrl1}/zone/getall`,
    AitDetails: `${urlChange ? serviceUrl : serviceUrl1
      }/straw-management/getSire`,
    GetData: `${urlChange ? serviceUrl : serviceUrl1
      }/v1/api/web/sire/semen-fill-grid`,
    SaveData: `${urlChange ? serviceUrl : serviceUrl1
      }/v1/api/web/sire/saveallsirestock`
  },

  // Master ------
  ZoneApi: {
    GetDetails: `${urlChange ? serviceUrl : serviceUrl1}/zone/get_table_data`,
    AddDetails: `${urlChange ? serviceUrl : serviceUrl1}/zone/add`,
    UpdateDetails: `${urlChange ? serviceUrl : serviceUrl1}/zone/edit`,
    VillageData: `${urlChange ? serviceUrl : serviceUrl1}/village/getall`,
    DelateData: `${urlChange ? serviceUrl : serviceUrl1}/zone/delete`,
    GetZoneByName: `${urlChange ? serviceUrl : serviceUrl1
      }/zone/get_zone_by_name`,
    GetAllZone: `${urlChange ? serviceUrl : serviceUrl1}/zone/getall`,
    SuspendApi: `${urlChange ? serviceUrl : serviceUrl1}/zone/is_suspended`
  },
  Herd: {
    GetDetails: `${urlChange ? serviceUrl : serviceUrl1}/herd/get_table_data`,
    AddDetails: `${urlChange ? serviceUrl : serviceUrl1}/herd/add`,
    UpdateDetails: `${urlChange ? serviceUrl : serviceUrl1}/herd/edit`,
    HerdByName: `${urlChange ? serviceUrl : serviceUrl1}/herd/get_by_name`,
    SuspendApi: `${urlChange ? serviceUrl : serviceUrl1}/herd/is_suspended`
  },
  SocietyApi: {
    GetDetails: `${urlChange ? serviceUrl : serviceUrl1}/lot/v2/get_table_data`,
    AddDetails: `${urlChange ? serviceUrl : serviceUrl1}/lot/add`,
    UpdateDetails: `${urlChange ? serviceUrl : serviceUrl1}/lot/edit`,
    DelateData: `${urlChange ? serviceUrl : serviceUrl1}/lot/delete`,
    LotByName: `${urlChange ? serviceUrl : serviceUrl1}/lot/get_by_name`,
    GetByHerdId: `${urlChange ? serviceUrl : serviceUrl1
      }/lot/get_by_name_herdid`,
    SuspendApi: `${urlChange ? serviceUrl : serviceUrl1}/lot/is_suspended`,
    GetAllLot: `${urlChange ? serviceUrl : serviceUrl1}/lot/getall`
  },
  FarmerApi: {
    GetDetails: `${urlChange ? serviceUrl : serviceUrl1
      }/farmer/v2/get_table_data`,
    AddDetails: `${urlChange ? serviceUrl : serviceUrl1}/farmer/add`,
    GetAllFarmer: `${urlChange ? serviceUrl : serviceUrl1}/farmer/getall`,
    UpdateDetails: `${urlChange ? serviceUrl : serviceUrl1}/farmer/edit`,
    FarmerTabel: `${urlChange ? serviceUrl : serviceUrl1
      }/farmer/v2/get_table_data`
  },
  ProjectApi: {
    GetDetails: `${urlChange ? serviceUrl : serviceUrl1}/virtualLot/getALL`,
    AddDetails: `${urlChange ? serviceUrl : serviceUrl1}/virtualLot/add`,
    UpdateDetails: `${urlChange ? serviceUrl : serviceUrl1}/virtualLot/edit`,
    DelateData: `${urlChange ? serviceUrl : serviceUrl1}/virtualLot/delete`
  },
  SireApi: {
    GetDetails: `${urlChange ? serviceUrl : serviceUrl1}/sire/get_table_data`,
    AddDetails: `${urlChange ? serviceUrl : serviceUrl1}/sire/add`,
    IssireSorted: `${urlChange ? serviceUrl : serviceUrl1}/sire/sireSorted`,
    GetAllSire: `${urlChange ? serviceUrl : serviceUrl1}/sire/getall`,
    UpdateDetails: `${urlChange ? serviceUrl : serviceUrl1}/sire/edit`,
    GetAll: `${urlChange ? serviceUrl : serviceUrl1}/source/getall`,
    GetBreed: `${urlChange ? serviceUrl : serviceUrl1}/breed/getall`,
    GetSourceDetails: `${urlChange ? serviceUrl : serviceUrl1
      }/api/getSourceDetails`,
    SavePurchaseDetails: `${urlChange ? serviceUrl : serviceUrl1
      }/api/savePurchaseDetails`
  },
  StaffApi: {
    GetDetails: `${urlChange ? serviceUrl : serviceUrl1}/staff/get_table_data`,
    AddDetails: `${urlChange ? serviceUrl : serviceUrl1}/staff/add`,
    UpdateDetails: `${urlChange ? serviceUrl : serviceUrl1}/staff/edit`,
    StaffByInseminator: `${urlChange ? serviceUrl : serviceUrl1
      }/staff/get_by_group_inseminator`,
    StaffByManeger: `${urlChange ? serviceUrl : serviceUrl1
      }/staff/get_by_group_manager`,
    DelateStaff: `${urlChange ? serviceUrl : serviceUrl1}/staff/delete`,
    SuspendApi: `${urlChange ? serviceUrl : serviceUrl1}/staff/is_suspended`
  },
  InsuranceApi: {
    GetDetails: `${urlChange ? serviceUrl : serviceUrl1
      }/insurance/get_table_data`,
    AddDetails: `${urlChange ? serviceUrl : serviceUrl1}/insurance/add`,
    UpdateDetails: `${urlChange ? serviceUrl : serviceUrl1}/insurance/edit`
  },
  SourceApi: {
    GetDetails: `${urlChange ? serviceUrl : serviceUrl1}/source/get_table_data`,
    AddDetails: `${urlChange ? serviceUrl : serviceUrl1}/source/add`,
    UpdateDetails: `${urlChange ? serviceUrl : serviceUrl1}/source/edit`
  },
  CalfApi: {
    GetDetails: `${urlChange ? serviceUrl : serviceUrl1
      }/calf-schedule/get_table_data`,
    AddDetails: `${urlChange ? serviceUrl : serviceUrl1}/calf-schedule/add`,
    UpdateDetails: `${urlChange ? serviceUrl : serviceUrl1}/calf-schedule/edit`
  },
  SmsApi: {
    GetDetails: `${urlChange ? serviceUrl : serviceUrl1
      }/sms-setting/get_table_data`,
    AddDetails: `${urlChange ? serviceUrl : serviceUrl1}/sms-setting/add`,
    UpdateDetails: `${urlChange ? serviceUrl : serviceUrl1}/sms-setting/edit`,
    DelateDetails: `${urlChange ? serviceUrl : serviceUrl1}/sms-setting/delete`,
    SmsAllData: `${urlChange ? serviceUrl : serviceUrl1}/sms_language/getall`
  },
  MasterMedicineApi: {
    GetDetails: `${urlChange ? serviceUrl : serviceUrl1
      }/medicine-route/get_table_data`,
    AddDetails: `${urlChange ? serviceUrl : serviceUrl1}/medicine-route/add`,
    UpdateDetails: `${urlChange ? serviceUrl : serviceUrl1}/medicine-route/edit`
  },
  DewormingApi: {
    GetDetails: `${urlChange ? serviceUrl : serviceUrl1
      }/deworming-type/get_table_data`,
    AddDetails: `${urlChange ? serviceUrl : serviceUrl1}/deworming-type/add`,
    UpdateDetails: `${urlChange ? serviceUrl : serviceUrl1}/deworming-type/edit`
  },
  VopostApi: {
    GetDetails: `${urlChange ? serviceUrl : serviceUrl1}/master/vo_post`,
    AddDetails: `${urlChange ? serviceUrl : serviceUrl1}/master/vo_post/add`,
    UpdateDetails: `${urlChange ? serviceUrl : serviceUrl1}/master/vo_post/edit`
  },
  VocategoryApi: {
    GetDetails: `${urlChange ? serviceUrl : serviceUrl1}/master/vo_category`,
    AddDetails: `${urlChange ? serviceUrl : serviceUrl1}/master/vo_category`,
    UpdateDetails: `${urlChange ? serviceUrl : serviceUrl1
      }/master/vo_category/edit`
  },
  ShiftApi: {
    GetDetails: `${urlChange ? serviceUrl : serviceUrl1}/master/shift`,
    AddDetails: `${urlChange ? serviceUrl : serviceUrl1}/master/shift/add`,
    UpdateDetails: `${urlChange ? serviceUrl : serviceUrl1}/master/shift/edit`
  },
  Shift_allocationApi: {
    GetDetails: `${urlChange ? serviceUrl : serviceUrl1
      }/master/shift_allocation`,
    AddDetails: `${urlChange ? serviceUrl : serviceUrl1
      }/master/shift_allocation`,
    UpdateDetails: `${urlChange ? serviceUrl : serviceUrl1
      }/master/shift_allocation`
  },
  MedicineApi: {
    CenterDetails: `${urlChange ? serviceUrl : serviceUrl1}/zone/getall`,
    AitDetails: `${urlChange ? serviceUrl : serviceUrl1
      }/straw-management/getSire`,
    GetData: `${urlChange ? serviceUrl : serviceUrl1
      }/v1/api/web/sire/semen-fill-grid`
  },

  // User Managment -----
  UserManagApi: {
    GetDetail: `${urlChange ? serviceUrl : serviceUrl1
      }/user/get_table_data?offset=0&limit=10000000&column_name=Username&sort_order=asc&search_term=&group_filter=0&company_filter=1`,
    GetZone: `${urlChange ? serviceUrl : serviceUrl1}/zone/getall?company=1`,
    GetGroup: `${urlChange ? serviceUrl : serviceUrl1
      }/group/getall?company_filter=1`,
    DeactiveApi: `${urlChange ? serviceUrl : serviceUrl1}/user/deactivate`
  },
  UserLoginDetail: {
    GetStaff: `${urlChange ? serviceUrl : serviceUrl1
      }/camp/fill-combo/fill-staff`,
    GetGroup: `${urlChange ? serviceUrl : serviceUrl1}/group/getall`,
    GetData: `${urlChange ? serviceUrl : serviceUrl1
      }/v1/api/web/account/get-appinfo`
  },

  // Alaram Api -----
  AlarmApi: {
    GetTabs: `${urlChange ? serviceUrl : serviceUrl1}/reportUnion/getFillTab`,
    getAlarmdata: `${urlChange ? serviceUrl : serviceUrl1}/alarm/alarm`
  },
  ActionPlanApi: {
    GetTabs: `${urlChange ? serviceUrl : serviceUrl1}/reportUnion/getFillTab`
  },

  // Cattle Managment Api -----
  Cattle_Deworming: {
    GetDetails: `${urlChange ? serviceUrl : serviceUrl1}/deworming/get-all`
  },
  Cattle_Vaccination: {
    GetDetails: `${urlChange ? serviceUrl : serviceUrl1}/vaccination/get-all`
  },
  Cattle_weight: {
    GetDetails: `${urlChange ? serviceUrl : serviceUrl1}/weight/get-all`,
    SaveBulkCalfEntry: `${urlChange ? serviceUrl : serviceUrl1
      }/cattle-report/SaveBulkCalfEntry`,
    GetCalfFillgrid: `${urlChange ? serviceUrl : serviceUrl1
      }/cattle-report/GetCalfFillgrid`
  },
  Cattle_Production: {
    GetDetails: `${urlChange ? serviceUrl : serviceUrl1}/production/get-all`,
    getFarmer: `${urlChange ? serviceUrl : serviceUrl1
      }/api/v1/cattle/details/details`
  },
  Cattleregistration: {
    SaveParents: `${urlChange ? serviceUrl : serviceUrl1
      }/api/saveParents`,
    CustomReport: `${urlChange ? serviceUrl : serviceUrl1
      }/cattle-report/get-herd-profile-by-id`,
    CustomReportByTable: `${urlChange ? serviceUrl : serviceUrl1
      }/cattle-report/get-profiles-table-by-id-and-level`,
    CustomReportByLot: `${urlChange ? serviceUrl : serviceUrl1
      }/cattle-report/get-lot-profile-by-id`,
    CustomReportByFarmer: `${urlChange ? serviceUrl : serviceUrl1
      }/cattle-report/tree-get-farmer-by-lotId`,
    FarmerById: `${urlChange ? serviceUrl : serviceUrl1
      }/cattle-report/get-farmer-profile-by-id`,
    TreeByFarmer: `${urlChange ? serviceUrl : serviceUrl1
      }/cattle-report/tree-get-cattle-by-farmerId`,
    AnimalData: `${urlChange ? serviceUrl : serviceUrl1
      }/cattle-report/get-animal-details-by-id`,
    AnimalData1: `${urlChange ? serviceUrl : serviceUrl1
      }/cattle-report/get-animal-details-by-id1`,
    AnimalGet: `${urlChange ? serviceUrl : serviceUrl1
      }/v1/api/web/animal/getanimaldata`,
    GetanimaldataNew: `${urlChange ? serviceUrl : serviceUrl1
      }/v1/api/web/animal/getanimaldataNew`,
    AnimalDetailsCurv: `${urlChange ? serviceUrl : serviceUrl1
      }/v1/api/web/animal/details-lact-curve`,
    AnimalWeightDetailsCurv: `${urlChange ? serviceUrl : serviceUrl1
      }/v1/api/web/animal/detailsWeightCurve`,
    LifeTimeDetails: `${urlChange ? serviceUrl : serviceUrl1
      }/cattle-report/get_lifetime_details`,
    ReproductionDetails: `${urlChange ? serviceUrl : serviceUrl1
      }/cattle-report/get_reproduction_details`,
    CattleByParity: `${urlChange ? serviceUrl : serviceUrl1
      }/cattle-report/get_cattle_by_parity`,
    TreeByArea: `${urlChange ? serviceUrl : serviceUrl1
      }/api/sp/Application/FillTree`,
    saveBreedingReproduction: `${urlChange ? serviceUrl : serviceUrl1
      }/api/sp/Breeding/SaveReproduction `,
    getAnimalDetails: `${urlChange ? serviceUrl : serviceUrl1}/api/getAnimalDetails`
  },

  // Project
  TransferAnimal: {
    GetZone: `${urlChange ? serviceUrl : serviceUrl1
      }/cattle-detail/getAllCenter`,
    GetVisitRoute: `${urlChange ? serviceUrl : serviceUrl1
      }/cattle-detail/getAllVisitRoutes`,
    GetData: `${urlChange ? serviceUrl : serviceUrl1
      }/cattle-detail/getTransferDatafill`,
    TransferData: `${urlChange ? serviceUrl : serviceUrl1
      }/cattle-detail/saveTransferData`,
    ProjectData: `${urlChange ? serviceUrl : serviceUrl1}/virtualLot/getALL`
    // GetFarmer: `${urlChange?serviceUrl:serviceUrl1}/cattle-detail/getAllVisitFarmer`
  },
  FIPTreatment: {
    GetSatff: `${urlChange ? serviceUrl : serviceUrl1
      }/v1/api/web/project/fill-consultant`,
    GetCamp: `${urlChange ? serviceUrl : serviceUrl1
      }/v1/api/web/camp/camp-master`,
    GetYear: `${urlChange ? serviceUrl : serviceUrl1
      }/v1/api/web/project/year-master`,
    GetFipData: `${urlChange ? serviceUrl : serviceUrl1
      }/v1/api/web/project/treatment-fill-grip`,
    GetMilkUnion: `${urlChange ? serviceUrl : serviceUrl1
      }/v1/api/web/application/company`,
    GetSocietyCode: `${urlChange ? serviceUrl : serviceUrl1
      }/straw-management/getAll`,
    GetMedicineData: `${urlChange ? serviceUrl : serviceUrl1
      }/v1/api/web/account/medicine-ledger`,
    GetCurrentData: `${urlChange ? serviceUrl : serviceUrl1
      }/v1/api/web/animal/details-current-data`,
    FipTreatmentSave: `${urlChange ? serviceUrl : serviceUrl1
      }/v1/api/web/project/save-fip-treatment`,
    Treatment2Save: `${urlChange ? serviceUrl : serviceUrl1
      }/v1/api/web/project/fip-treatment-det`,
    GetDiagnosis: `${urlChange ? serviceUrl : serviceUrl1
      }/v1/api/web/health/get-diagnosis`
  },

  CampMaster: {
    GetZone: `${urlChange ? serviceUrl : serviceUrl1}/zone/getall`,
    GetTableData: `${urlChange ? serviceUrl : serviceUrl1
      }/vehicle-registeration/get_table_data`,
    getVillageName: `${urlChange ? serviceUrl : serviceUrl1
      }/lot/filter_by_dcs_code`,
    getMasterTable: `${urlChange ? serviceUrl : serviceUrl1
      }/api/sp/get-master-table`
  },
  CampPlanning: {
    GetYear: `${urlChange ? serviceUrl : serviceUrl1
      }/v1/api/web/project/year-master`,
    GetProject: `${urlChange ? serviceUrl : serviceUrl1}/virtualLot/getALL`,
    GetFip: `${urlChange ? serviceUrl : serviceUrl1
      }/v1/api/web/project/fill-consultant`,
    GetCamp: `${urlChange ? serviceUrl : serviceUrl1
      }/v1/api/web/camp/camp-master`,
    GetCampGrid: `${urlChange ? serviceUrl : serviceUrl1
      }/v1/api/web/project/camp-fill-grid`,
    GetCampFill: `${urlChange ? serviceUrl : serviceUrl1
      }/v1/api/web/project/camp-fill-date-grid`
  },
  AllocationApi: {
    GetYear: `${urlChange ? serviceUrl : serviceUrl1
      }/v1/api/web/project/year-master`,
    GetFipList: `${urlChange ? serviceUrl : serviceUrl1
      }/v1/api/web/project/fill-consultant`,
    GetProject: `${urlChange ? serviceUrl : serviceUrl1}/virtualLot/getALL`,
    GetDcs: `${urlChange ? serviceUrl : serviceUrl1}/straw-management/getAll`,
    GetData: `${urlChange ? serviceUrl : serviceUrl1
      }/v1/api/web/project/allote-fill-grid`,
    SaveAllocation: `${urlChange ? serviceUrl : serviceUrl1
      }/v1/api/web/project/save-allocation`,
    SaveDcsTest: `${urlChange ? serviceUrl : serviceUrl1
      }/api/sp/Project/SAVECAMPPlaning`
  },
  AddAnimal: {
    GetTableData: `${urlChange ? serviceUrl : serviceUrl1
      }/breed/get_table_data`,
    EditData: `${urlChange ? serviceUrl : serviceUrl1}/breed/edit`,
    RoutData: `${urlChange ? serviceUrl : serviceUrl1}/camp/fill-combo/route`,
    SexData: `${urlChange ? serviceUrl : serviceUrl1}/camp/fill-combo/sex`,
    SpeciesData: `${urlChange ? serviceUrl : serviceUrl1
      }/camp/fill-combo/species`,
    StaffData: `${urlChange ? serviceUrl : serviceUrl1
      }/camp/fill-combo/fill-staff`,
    SireData: `${urlChange ? serviceUrl : serviceUrl1
      }/straw-management/getSire`,
    BreedData: `${urlChange ? serviceUrl : serviceUrl1}/camp/fill-combo/breed`
  },
  CampReport: {
    GetYear: `${urlChange ? serviceUrl : serviceUrl1
      }/v1/api/web/project/year-master`,
    GetProject: `${urlChange ? serviceUrl : serviceUrl1}/virtualLot/getALL`,
    GetFip: `${urlChange ? serviceUrl : serviceUrl1
      }/v1/api/web/project/fill-consultant`,
    GetCamp: `${urlChange ? serviceUrl : serviceUrl1
      }/v1/api/web/camp/camp-master`
  },

  // Transfer Api -----
  // Animal Api -----
  Animal_Zone: {
    GetZone: `${urlChange ? serviceUrl : serviceUrl1
      }/cattle-detail/getAllCenter`,
    GetVisitRoute: `${urlChange ? serviceUrl : serviceUrl1
      }/cattle-detail/getAllVisitRoutes`,
    GetData: `${urlChange ? serviceUrl : serviceUrl1
      }/cattle-detail/getTransferDatafill`,
    TransferData: `${urlChange ? serviceUrl : serviceUrl1
      }/cattle-detail/saveTransferData`
  },

  // farmer Api -----
  Farmer_Zone: {
    GetZone: `${urlChange ? serviceUrl : serviceUrl1
      }/cattle-detail/getAllCenter`,
    GetVisitRoute: `${urlChange ? serviceUrl : serviceUrl1
      }/cattle-detail/getAllVisitRoutes`,
    GetData: `${urlChange ? serviceUrl : serviceUrl1
      }/cattle-detail/getTransferDatafill`,
    TransferData: `${urlChange ? serviceUrl : serviceUrl1
      }/cattle-detail/saveTransferData`
  },
  Village_Zone: {
    GetZone: `${urlChange ? serviceUrl : serviceUrl1
      }/cattle-detail/getAllCenter`,
    GetVisitRoute: `${urlChange ? serviceUrl : serviceUrl1
      }/cattle-detail/getAllVisitRoutes`,
    GetData: `${urlChange ? serviceUrl : serviceUrl1
      }/cattle-detail/getTransferDatafill`,
    TransferData: `${urlChange ? serviceUrl : serviceUrl1
      }/cattle-detail/saveTransferData`
  },
  Project_Zone: {
    GetProject: `${urlChange ? serviceUrl : serviceUrl1}/virtualLot/getALL`,
    GetData: `${urlChange ? serviceUrl : serviceUrl1
      }/cattle-detail/getTransferDatafill`,
    TransferData: `${urlChange ? serviceUrl : serviceUrl1
      }/cattle-detail/saveTransferData`
  },

  //Straw Managment
  BalanceApi: {
    GetZone: `${urlChange ? serviceUrl : serviceUrl1}/zone/getall`,
    GetAit: `${urlChange ? serviceUrl : serviceUrl1
      }/straw-management/getNewStaff`,
    GetData: `${urlChange ? serviceUrl : serviceUrl1
      }/v1/api/web/sire/semen-fill-grid`,
    SaveData: `${urlChange ? serviceUrl : serviceUrl1
      }/v1/api/web/sire/saveallsirestock`
  },
  DistributionAPi: {
    GetZone: `${urlChange ? serviceUrl : serviceUrl1}/zone/getall`,
    GetItem: `${urlChange ? serviceUrl : serviceUrl1}/straw-management/getItem`,
    GetStaff: `${urlChange ? serviceUrl : serviceUrl1
      }/straw-management/getNewStaff`,
    GetSupplier: `${urlChange ? serviceUrl : serviceUrl1
      }/sire-supply/getSireGroupStaff`,
    EditDetails: `${urlChange ? serviceUrl : serviceUrl1
      }/v1/api/web/account/straw-dist-fill-staff`
  },
  DamageApi: {
    GetZone: `${urlChange ? serviceUrl : serviceUrl1}/zone/getall`,
    GetAit: `${urlChange ? serviceUrl : serviceUrl1
      }/straw-management/getNewStaff`,
    GetData: `${urlChange ? serviceUrl : serviceUrl1
      }/v1/api/web/sire/semen-fill-grid`,
    SaveData: `${urlChange ? serviceUrl : serviceUrl1
      }/v1/api/web/sire/saveallsirestock`
  },
  TransferApi: {
    GetZone: `${urlChange ? serviceUrl : serviceUrl1}/zone/getall`,
    GetAit: `${urlChange ? serviceUrl : serviceUrl1
      }/straw-management/getNewStaff`,
    GetTransferAit: `${urlChange ? serviceUrl : serviceUrl1
      }/straw-management/getNewStaff`,
    GetData: `${urlChange ? serviceUrl : serviceUrl1
      }/v1/api/web/sire/semen-fill-grid`,
    SaveData: `${urlChange ? serviceUrl : serviceUrl1
      }/v1/api/web/sire/saveallsirestock`
  },
  ReturnSemenApi: {
    GetZone: `${urlChange ? serviceUrl : serviceUrl1}/zone/getall`,
    GetAit: `${urlChange ? serviceUrl : serviceUrl1
      }/straw-management/getNewStaff`,
    GetTransferAit: `${urlChange ? serviceUrl : serviceUrl1
      }/straw-management/getNewStaff`,
    GetData: `${urlChange ? serviceUrl : serviceUrl1
      }/v1/api/web/sire/semen-fill-grid`,
    SaveData: `${urlChange ? serviceUrl : serviceUrl1
      }/v1/api/web/sire/saveallsirestock`
  },
  ReturnSupplierApi: {
    GetZone: `${urlChange ? serviceUrl : serviceUrl1}/zone/getall`,
    GetAit: `${urlChange ? serviceUrl : serviceUrl1
      }/straw-management/getNewStaff`,
    GetTransferAit: `${urlChange ? serviceUrl : serviceUrl1
      }/straw-management/getNewStaff`,
    GetData: `${urlChange ? serviceUrl : serviceUrl1
      }/v1/api/web/sire/semen-fill-grid`,
    SaveData: `${urlChange ? serviceUrl : serviceUrl1
      }/v1/api/web/sire/saveallsirestock`
  },
  MissingStrawApi: {
    GetZone: `${urlChange ? serviceUrl : serviceUrl1}/zone/getall`,
    GetAit: `${urlChange ? serviceUrl : serviceUrl1
      }/straw-management/getNewStaff`,
    GetData: `${urlChange ? serviceUrl : serviceUrl1
      }/v1/api/web/sire/semen-fill-grid`,
    SaveData: `${urlChange ? serviceUrl : serviceUrl1
      }/v1/api/web/sire/saveallsirestock`
  },
  MannualApi: {
    GetZone: `${urlChange ? serviceUrl : serviceUrl1}/zone/getall`,
    GetAit: `${urlChange ? serviceUrl : serviceUrl1
      }/straw-management/getNewStaff`,
    GetData: `${urlChange ? serviceUrl : serviceUrl1
      }/v1/api/web/sire/semen-fill-grid`,
    SaveData: `${urlChange ? serviceUrl : serviceUrl1
      }/v1/api/web/sire/saveallsirestock`
  },
  CampApi: {
    GetZone: `${urlChange ? serviceUrl : serviceUrl1}/zone/getall`,
    GetAit: `${urlChange ? serviceUrl : serviceUrl1
      }/straw-management/getNewStaff`,
    GetData: `${urlChange ? serviceUrl : serviceUrl1
      }/v1/api/web/sire/semen-fill-grid`,
    SaveData: `${urlChange ? serviceUrl : serviceUrl1
      }/v1/api/web/sire/saveallsirestock`
  },
  RecoveryApi: {
    GetZone: `${urlChange ? serviceUrl : serviceUrl1}/zone/getall`,
    GetAit: `${urlChange ? serviceUrl : serviceUrl1
      }/straw-management/getNewStaff`,
    GetData: `${urlChange ? serviceUrl : serviceUrl1
      }/v1/api/web/sire/semen-fill-grid`,
    SaveData: `${urlChange ? serviceUrl : serviceUrl1
      }/v1/api/web/sire/saveallsirestock`
  },
  SupplieStockApi: {
    GetZone: `${urlChange ? serviceUrl : serviceUrl1}/zone/getall`,
    GetStaff: `${urlChange ? serviceUrl : serviceUrl1
      }/sire-supply/getSireGroupStaff`,
    GetSire: `${urlChange ? serviceUrl : serviceUrl1}/straw-management/getSire`,
    SaveData: `${urlChange ? serviceUrl : serviceUrl1
      }/v1/api/web/sire/saveallsirestock`
  },
  AitStockApi: {
    GetZone: `${urlChange ? serviceUrl : serviceUrl1}/zone/getall`,
    GetStaff: `${urlChange ? serviceUrl : serviceUrl1
      }/straw-management/getSire`,
    GetSire: `${urlChange ? serviceUrl : serviceUrl1
      }/straw-management/getNewGroupStaff`,
    SaveData: `${urlChange ? serviceUrl : serviceUrl1
      }/v1/api/web/sire/saveallsirestock`,
    SireUpdate: `${urlChange ? serviceUrl : serviceUrl1
      }/v1/api/mobile-app/account/adjustsirestock`,
    SireUpdateNew: `${urlChange ? serviceUrl : serviceUrl1
      }/v1/api/mobile-app/account/changesirestock`
  },
  SemenSupplierApi: {
    GetSemenData: `${urlChange ? serviceUrl : serviceUrl1
      }/straw-management/getSuppliergridSemenSupply`,
    GetItemData: `${urlChange ? serviceUrl : serviceUrl1
      }/straw-management/getItem`,
    SaveStraw: `${urlChange ? serviceUrl : serviceUrl1
      }/straw-management/strawMgmtSave`,
    SaveStrawNew: `${urlChange ? serviceUrl : serviceUrl1
      }/straw-management/strawMgmtSaveNew`,
    SaveItem: `${urlChange ? serviceUrl : serviceUrl1
      }/straw-management/saveOtherItem`,
    SaveItem1: `${urlChange ? serviceUrl : serviceUrl1
      }/straw-management/saveOtherItem1`,
    SemenRoute: `${urlChange ? serviceUrl : serviceUrl1
      }/v1/api/web/master/semen-route`,
    GetSemenData: `${urlChange ? serviceUrl : serviceUrl1
      }/v1/api/web/account/getallsemen-requestnew`
  },
  SemenRequestApi: {
    dowunloadData: `${urlChange ? serviceUrl : serviceUrl1
      }/straw-management/semenSupply`
  },

  // Map Analytics Api:-
  MapAnalyticsApi: {
    AnimalGet: `${urlChange ? serviceUrl : serviceUrl1
      }/animal-analytics/get_condition_by_datatype?data_type=0`,
    // ConditionGet: `${urlChange?serviceUrl:serviceUrl1}/animal-analytics/get_condition_by_datatype?data_type=0`,
    ProjectGetData: `${urlChange ? serviceUrl : serviceUrl1
      }/animal-analytics/get_virtual_lot`
  },

  // MPP
  MppAPI: {
    Select: `${urlChange ? serviceUrl : serviceUrl1
      }/v1/api/web/project/fill-consultant`,
    CampSelect: `${urlChange ? serviceUrl : serviceUrl1
      }/v1/api/web/camp/camp-master`,
    SelectYear: `${urlChange ? serviceUrl : serviceUrl1
      }/v1/api/web/project/year-master`,
    MppView: `${urlChange ? serviceUrl : serviceUrl1
      }/v1/api/web/mpp/dsc-pooled-fill-grid`,
    FarmerView: `${urlChange ? serviceUrl : serviceUrl1
      }/v1/api/web/mpp/farmer-pooled-fill-grid`,
    AnimalView: `${urlChange ? serviceUrl : serviceUrl1
      }/v1/api/web/mpp/animal-fill-grid`,
    SimpleMaster: `${urlChange ? serviceUrl : serviceUrl1
      }/v1/api/web/common/sample-type`,
    ResultMaster: `${urlChange ? serviceUrl : serviceUrl1
      }/v1/api/web/mpp/mpp-test-result`,
    DcsSave: `${urlChange ? serviceUrl : serviceUrl1
      }/v1/api/web/mpp/save-dcs-text`,
    FarmerSave: `${urlChange ? serviceUrl : serviceUrl1
      }/v1/api/web/mpp/save-farmer-test`,
    AnimalSave: `${urlChange ? serviceUrl : serviceUrl1
      }/v1/api/web/mpp/save-animal-test`,
    MppReportData: `${urlChange ? serviceUrl : serviceUrl1
      }/api/Mpp/MPPanimalTest`
  },

  // Doctor Track Api:
  DoctorApi: {
    getZone: `${urlChange ? serviceUrl : serviceUrl1}/zone/getall`,
    getUser: `${urlChange ? serviceUrl : serviceUrl1}/user/username`,
    getData: `${urlChange ? serviceUrl : serviceUrl1}/tracking`,
    getData: `${urlChange ? serviceUrl : serviceUrl1}/tracking`,
    getDoctorTrackData: `${urlChange ? serviceUrl : serviceUrl1}/tracking`,
    filterbyUser: `${urlChange ? serviceUrl : serviceUrl1}/user/username`
  },

  // Milk Test:
  MilkTestApi: {
    getAll: `${urlChange ? serviceUrl : serviceUrl1
      }/production/milk/milk-entry`,
    saveBulkMilkAPi: `${urlChange ? serviceUrl : serviceUrl1
      }/production/milk/SaveBulkMilkEntry`,
    SaveIndividualMilkEntry: `${urlChange ? serviceUrl : serviceUrl1
      }/production/milk/SaveIndividualMilkEntry`,
    getParityWiseData: `${urlChange ? serviceUrl : serviceUrl1
      }/api/sp/Paritywisemilk`,
    addMilkProduction: `${urlChange ? serviceUrl : serviceUrl1
      }/api/v1/cattle/production-add`
  },
  BulkMilkApi: {
    viewApi: `${urlChange ? serviceUrl : serviceUrl1
      }/v1/api/web/milk/milk-fill-grid`,
    saveAPi: `${urlChange ? serviceUrl : serviceUrl1
      }/v1/api/web/milk/save-milk-entry`
  },
  MilKPdCheck: {
    getApi: `${urlChange ? serviceUrl : serviceUrl1
      }/api/sp/Breeding/FillPDTestgrid`
  },

  // Breeding Details:
  BreedingDetailsApi: {
    // fillGrid: `${testingUrl}/v1/api/web/breeding/fillaigrid`,
    fillGrid: `${urlChange ? serviceUrl : serviceUrl1
      }/reproduction/Breeding/FillAIGrid`,
    // getLifeTimeProduction: `${testingUrl}/v1/api/web/dbo/getlifetimereproduction`,
    getLifeTimeProduction: `${urlChange ? serviceUrl : serviceUrl1
      }/reproduction/getLifeTimeProduction`,
    dryOff: `${urlChange ? serviceUrl : serviceUrl1}/api/sp/Breeding/UpdateDry`,
    saveAi: `${urlChange ? serviceUrl : serviceUrl1}/api/sp/breeding/AISave`,
    calvingAPi: `${urlChange ? serviceUrl : serviceUrl1
      }/api/sp/breeding/CalvingSave`,
    pdDataApi: `${urlChange ? serviceUrl : serviceUrl1
      }/api/sp/breeding/PD2Save`,
    calfSave: `${urlChange ? serviceUrl : serviceUrl1
      }/v1/api/web/animal/save-calfphoto`,
    dryResonsApi: `${urlChange ? serviceUrl : serviceUrl1
      }/api/sp/Application/GetOnetimedata`,
    deleteTagId: `${urlChange ? serviceUrl : serviceUrl1
      }/api/sp/Breeding/delete`,
    deleteTagId1: `${urlChange ? serviceUrl : serviceUrl1
      }/api/sp/Breeding/delete-parity`,
    editAi: `${urlChange ? serviceUrl : serviceUrl1}/api/sp/Breeding/EditAi`,
    saveMilkPdTest: `${urlChange ? serviceUrl : serviceUrl1
      }/api/sp/Breeding/SaveMilkPDTest`,
    saveParitySave: `${urlChange ? serviceUrl : serviceUrl1
      }/api/sp/Breeding/PD2Saveparity`,
    calvingSaveparity: `${urlChange ? serviceUrl : serviceUrl1
      }/api/sp/Breeding/CalvingSaveparity`,
    getVillageData: `${urlChange ? serviceUrl : serviceUrl1}/api/sp/GetVillage`,
    editAnimal: `${urlChange ? serviceUrl : serviceUrl1
      }/api/Breeding/editanimal`
  },

  DisposalApi: {
    DisposalTypeApi: `${urlChange ? serviceUrl : serviceUrl1
      }/api/sp/Application/GetOnetimedata`,
    SaveDisposal: `${urlChange ? serviceUrl : serviceUrl1
      }/api/sp/animal/DisposalSave`,
    RefreshApi: `${urlChange ? serviceUrl : serviceUrl1
      }/v1/api/web/animal/refresh-animal`
  },

  // Visit Registration

  visitRegistration: {
    GetVoVisitRegistration: `${urlChange ? serviceUrl : serviceUrl1
      }/health/visit`,
    GetAddVisitData: `${urlChange ? serviceUrl : serviceUrl1
      }/account/visit_rate`,
    GetVisitRateTimeAddVisit: `${urlChange ? serviceUrl : serviceUrl1
      }/account/visit_rate/by_time_visit_type`,
    GetAllDataofModalAddVisit: `${urlChange ? serviceUrl : serviceUrl1
      }/species/getall`,
    GetVisitTypeDataAddVisit: `${urlChange ? serviceUrl : serviceUrl1
      }/master/visit_type`,
    GetMobileNumberAddVisit: `${urlChange ? serviceUrl : serviceUrl1}/farmer`,
    GetIdAddVisit: `${urlChange ? serviceUrl : serviceUrl1}/lot/get_by_id`,
    GetTimeAddVisit: `${urlChange ? serviceUrl : serviceUrl1
      }/application/route_vo_allocation`,
    GetAIPDAllData: `${urlChange ? serviceUrl : serviceUrl1
      }/health/farmer_complaint`,
    GetAddressAddVisit: `${urlChange ? serviceUrl : serviceUrl1
      }/farmer/250370/address`,
    GetSortedAIPD: `${urlChange ? serviceUrl : serviceUrl1
      }/v1/api/web/application/get-dcs-ait-sorted`,
    GetVisitDetails: `${urlChange ? serviceUrl : serviceUrl1
      }/health/visit/details`
  },

  visitAITRegistration: {
    GetAITVisitRegistration: `${urlChange ? serviceUrl : serviceUrl1
      }/health/visit`,
    GetAddVisitData: `${urlChange ? serviceUrl : serviceUrl1
      }/account/visit_rate`,
    GetAllDataAddVisit: `${urlChange ? serviceUrl : serviceUrl1}/zone/getall`,
    GetVisitTypeDataAddVisit: `${urlChange ? serviceUrl : serviceUrl1
      }/master/visit_type`,
    GetMobileNumberAddVisit: `${urlChange ? serviceUrl : serviceUrl1}/farmer`,
    GetIdAddVisit: `${urlChange ? serviceUrl : serviceUrl1}/lot/get_by_id`,
    GetTimeAddVisit: `${urlChange ? serviceUrl : serviceUrl1
      }/application/route_vo_allocation`,
    GetVisitRateTimeAddVisit: `${urlChange ? serviceUrl : serviceUrl1
      }/account/visit_rate/by_time_visit_type`,
    GetAllDataofModalAddVisit: `${urlChange ? serviceUrl : serviceUrl1
      }/species/getall`,
    GetAIPDAllData: `${urlChange ? serviceUrl : serviceUrl1
      }/health/farmer_complaint`,
    GetAddressAddVisit: `${urlChange ? serviceUrl : serviceUrl1
      }/farmer/250370/address`,
    GetADPDAddress: `${urlChange ? serviceUrl : serviceUrl1}/farmer/0/address`,
    GetSortedAIPD: `${urlChange ? serviceUrl : serviceUrl1
      }/v1/api/web/application/get-dcs-ait-sorted`,
    GetVisitDetails: `${urlChange ? serviceUrl : serviceUrl1
      }/health/visit/details`
  },

  visitReallocation: {
    GetReallocation: `${urlChange ? serviceUrl : serviceUrl1
      }/v1/api/web/health/visitreallocation`,
    GetComplaintCodeOne: `${urlChange ? serviceUrl : serviceUrl1
      }/health/visit/caseform`,
    GetComplaintCodetwoFour: `${urlChange ? serviceUrl : serviceUrl1
      }/health/visit/certifications`,
    GetEditVisitData: `${urlChange ? serviceUrl : serviceUrl1}/health/visit`,
    GetAllDataUser: `${urlChange ? serviceUrl : serviceUrl1}/zone/getall`,
    GetNameFilter: `${urlChange ? serviceUrl : serviceUrl1
      }/staff/get_table_data`
  },
  visitCancellation: {
    GetCancellation: `${urlChange ? serviceUrl : serviceUrl1
      }/v1/api/web/health/visitreallocation`,
    GetAllDataUser: `${urlChange ? serviceUrl : serviceUrl1}/zone/getall`,
    GetFilterUser: `${urlChange ? serviceUrl : serviceUrl1}/herd/getall`,
    GetLotAll: `${urlChange ? serviceUrl : serviceUrl1}/lot/getbyherd_id`,
    GetVONameFilter: `${urlChange ? serviceUrl : serviceUrl1
      }/staff/get_table_data`,
    GetComplaintCodeOne: `${urlChange ? serviceUrl : serviceUrl1
      }/health/visit/caseform`,
    GetComplaintCodetwoFour: `${urlChange ? serviceUrl : serviceUrl1
      }/health/visit/certifications`
  },

  //Lab Show Data Api:-
  Hemato_cytology: {
    showHematoData: `${urlChange ? serviceUrl : serviceUrl1
      }/lab/ShowHeamtoCytology`,
    showBiochemistryData: `${urlChange ? serviceUrl : serviceUrl1
      }/lab/ShowBiochemistry`,
    showGIParasitismData: `${urlChange ? serviceUrl : serviceUrl1
      }/lab/ShowGIParasitism`,
    showUrinalysisData: `${urlChange ? serviceUrl : serviceUrl1
      }/lab/ShowUrinalysis`,
    showImmunologyData: `${urlChange ? serviceUrl : serviceUrl1
      }/lab/ShowImmunology`,
    showSCMData: `${urlChange ? serviceUrl : serviceUrl1}/lab/ShowSCM`,
    showAntigramData: `${urlChange ? serviceUrl : serviceUrl1
      }/lab/ShowAntigram`,
    showExecuteSpData: `${urlChange ? serviceUrl : serviceUrl1
      }/lab/ShowExecuteSp`,
    showToxicologyData: `${urlChange ? serviceUrl : serviceUrl1
      }/lab/ShowToxicology`,
    showExecuteMilkChemistryData: `${urlChange ? serviceUrl : serviceUrl1
      }/lab/ShowExecuteMilkChemistry`,
    showExecuteModTechData: `${urlChange ? serviceUrl : serviceUrl1
      }/lab/ShowExecuteModTech`,
    showExecuteRemarksData: `${urlChange ? serviceUrl : serviceUrl1
      }/lab/ShowExecuteRemarks`,
    getRegisterData: `${urlChange ? serviceUrl : serviceUrl1
      }/lab/ExecuteSelectLabRegisterData`,
    getSampleData: `${urlChange ? serviceUrl : serviceUrl1}/lab/GetSampletype`,
    saveTestingData: `${urlChange ? serviceUrl : serviceUrl1}/lab/SaveTesting`,
    distinctDateApi: `${urlChange ? serviceUrl : serviceUrl1
      }/lab/DistinctRegistrationDates`,
    distinctDateApi2: `${urlChange ? serviceUrl : serviceUrl1
      }/lab/ShowTagidData`,
    allDropData: `${urlChange ? serviceUrl : serviceUrl1}/lab/AllDropData`,
    refBydataApi: `${urlChange ? serviceUrl : serviceUrl1
      }/api/sp/Application/GetMasterdata`,
    labCertificate: `${urlChange ? serviceUrl : serviceUrl1
      }/lab/LabCertificate`,
    lab_Certificate1: `${urlChange ? serviceUrl : serviceUrl1
      }/lab/Lab_Certificate1`
  },

  Ahc_Report: {
    SelectAhc: `${urlChange ? serviceUrl : serviceUrl1}/ahc/reportnames`,
    FilterAhc: `${urlChange ? serviceUrl : serviceUrl1}/ahc/ahc-report`
  },
  SAPStock: {
    selectSAPStock: `${urlChange ? serviceUrl : serviceUrl1}/api/GetSapStock`
  }
}
