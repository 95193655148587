import React from "react";

// CustomDot component
export const CustomDotAI = (props) => {
  const { cx, cy } = props;

  return (
    <g>
      <text x={cx} y={cy} textAnchor="middle" fill="#646464" fontSize="19px">+</text>
    </g>
  );
};

export const CustomMilkKG = (props) => {
  const { cx, cy } = props;

  return (
    <g>
      <text x={cx} y={cy} textAnchor="middle" fill="#0036FF" color="#0036FF" fontSize="13px">●</text>
    </g>
  );
};


export const CustomDotPD = (props) => {
  const { cx, cy, payload } = props;
  const pdData = payload.PDlable;
  return (
    <g>
      <text x={cx} y={cy} textAnchor="start" fill="#007302" style={{ paddingLeft: "5px" }} fontSize="12px"> {pdData} </text>
    </g>
  );
};

export const CustomDotExpMilk = (props) => {
  const { cx, cy, payload } = props;
  const expmilkData = payload.ExpMilkLBL;
  return (
    <g>
      <text x={cx} y={cy} textAnchor="end" fill="#5C009B" fontSize="12px">
        {expmilkData}
      </text>
    </g>
  );
};

export const CustomLineStandardMilk = (props) => {
  const { cx, cy, stroke, payload, value } = props;
  // console.log("Dot position", { cx, cy, payload });
  return (
    <circle cx={cx} cy={cy}  stroke={stroke} fill="#82ca9d" style={{ zIndex: undefined }} />
  );
};